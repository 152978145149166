import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { transAnimation } from '../hero-section/hero-section.component';
import { ScripturePayload, ScriptureService } from '../services/scripture.service';

@Component({
  selector: 'ewd-salvation',
  templateUrl: './salvation.component.html',
  styleUrls: ['./salvation.component.scss'],
  animations: [
    trigger('revealBenefit', [
      transition(':enter', [
        useAnimation(transAnimation, {
          params: {
            opacity: 0,
            time: '3s'
          }
        })
      ]),
    ])
  ],
})
export class SalvationComponent implements OnInit, OnDestroy {
  public pretextScripture = {bk: 'romans', chp: '10', vrs: '10'};
  public pretextScripture$!: Observable<string[]>;
  public preFinalActions = {bk: 'hebrews', chp: '10', vrs: '25'};
  public preFinalActions$!: Observable<string[]>;
  public benefits: string[] = [
    'Saves',
    'Delivers',
    'Sets Free',
    'Heals',
    'Changes Lives',
    'Gives Happiness',
    'Turns Around',
  ];
  public focus = 0;
  private benefitsInterval!: Subscription;
  public afterSalvation: string[] = [
    'Never FORGET this day, write it down.',
    'Keep SPEAKING \'I AM SAVED\', DO NOT keep your mouth shut!',
    'BELIEVE your FREEDOM in Christ STRONGLY in your heart.',
    'Stay saved, study the word, get to KNOW Jesus more, the person of whom you have received is in His WORD.',
    'You are Brand NEW, REDEEMED, FREE!',
    'Your background, family, nationality does NOT AFFECT you anymore, you are a MEMBER of the household of FAITH.',
    'You are CLEAN every with!',
    'You are RELEASED for generational blessings, no more curses!',
    'SUBSCRIBE to every GOOD WORK in SCRIPTURES',
    'Only THINK on whatsoever IS TRUE, NOBLE, RIGHT, PURE, LOVELY, ADMIRABLE, EXCELLENT, PRAISEWORTHY!',
    'MAKE conscious decisions everyday to REMAIN SAVED and CONNECTED to God!',
  ];

  constructor(private scriptureService: ScriptureService) { }

  ngOnDestroy(): void {
    this.benefitsInterval.unsubscribe();
  }

  ngOnInit(): void {
    this.pretextScripture$ = this.getScripture(this.pretextScripture);
    this.preFinalActions$ = this.getScripture(this.preFinalActions);

    this.benefitsInterval = interval(3000)
      .subscribe(() => {
        this.focus += 1;

        if (this.focus >= this.benefits.length) {
          this.focus = 0;
        }
      });
  }

  public getScripture(payload: ScripturePayload): Observable<string[]> {
    return this.scriptureService.getScripture(payload);
  }
}
