<div id="products" class="d-flex flex-column container mt-3 px-0">
    <p class="mx-2 mx-sm-0 my-0 mat-headline text-uppercase text-dark">Our Products</p>

    <div class="d-flex flex-column px-0 px-sm-3 py-5 mt-5 rounded-3" style="background-color: #fff2e1;">
        <div class="d-flex w-100 flex-wrap-reverse flex-wrap">
            <div class="d-flex flex-column col-12 col-sm-6 col-md-7 px-2 px-sm-3 px-md-5">
                <ewd-imole-logo class="mt-auto"></ewd-imole-logo>

                <div class="mt-3 mb-auto">
                    <span>
                        We facilitate our customers in tracking and controlling their energy consumption and by consequence
                        striking a balance between cost
                        and usage.
                    </span>
                    <span>
                        We are looking to solve Monitoring and Control of Energy Consumption related charges at the
                        level of each load (appliance) or across
                        loads in a building (as an example).
                    </span>
                </div>

                <div class="d-flex flex-wrap w-100">
                    <a mat-raised-button class="mt-3 mt-md-5 px-4 rounded-pill" href="https://imole.com.ng" target="_blank"
                        color="primary" style="width: fit-content;">
                        <span class="text-white">Learn more</span>
                    </a>

                    <a mat-raised-button class="mt-3 mt-md-5 ms-2 mx-md-2 px-4 rounded-pill" href="https://flutterwave.com/store/imole"
                        target="_blank" color="primary" style="width: fit-content;">
                        <span class="text-white">Shop</span>
                    </a>
                </div>
            </div>
            <div class="d-flex flex-column position-relative col-12 col-sm-6 col-md-5 mb-5 mb-sm-0"
                style="max-height: 720px;">
                <div #productImgContainer class="d-flex w-100 my-auto" style="overflow-y: hidden; overflow-x: auto;">
                    <img *ngFor="let item of productImages; index as i;" class="w-100 m-auto" src="{{item}}"
                        alt="shots-{{i + 1}}">
                </div>

                <div class="d-flex w-100 mt-3" style="position: absolute; top: calc(50% - 40px / 2);">
                    <button mat-mini-fab class="d-none d-sm-block rounded-circle" color="accent"
                        (click)="productImgContainer.scrollBy({left: -296, top: 0, behavior: 'smooth'})">
                        <mat-icon class="m-auto">arrow_backward</mat-icon>
                    </button>
                    <button mat-mini-fab class="d-none d-sm-block ms-auto rounded-circle" color="accent"
                        (click)="productImgContainer.scrollBy({left: 296, top: 0, behavior: 'smooth'})">
                        <mat-icon class="m-auto">arrow_forward</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column container py-5">
    <div class="d-flex flex-column w-100 py-2 py-sm-5 ewd-product">
        <div class="d-flex w-100 flex-wrap">
            <div
                class="d-flex flex-column col-12 col-md-4 py-3 my-md-auto ewd-product-shots-container position-relative">
                <div #imgContainer class="d-flex flex-column flex-wrap w-100"
                    style="overflow-y: hidden; overflow-x: auto;">
                    <img *ngFor="let item of images; index as i;" class="h-100 m-auto" src="{{item}}"
                        alt="shots-{{i + 1}}">
                </div>

                <button mat-mini-fab class="d-none d-sm-block" color="accent"
                    style="position: absolute; border-radius: 50%; top: calc(50% - (40px/2) - 16px); left: calc(-40px/2);"
                    (click)="imgContainer.scrollBy({left: -240, top: 0, behavior: 'smooth'})">
                    <mat-icon class="m-auto">arrow_backward</mat-icon>
                </button>
                <button mat-mini-fab class="d-none d-sm-block" color="accent"
                    style="position: absolute; border-radius: 50%; top: calc(50% - (40px/2) - 16px); right: calc(-40px/2);"
                    (click)="imgContainer.scrollBy({left: 240, top: 0, behavior: 'smooth'})">
                    <mat-icon class="m-auto">arrow_forward</mat-icon>
                </button>
            </div>
            <div class="d-flex flex-column col-12 col-md-8 px-2 px-sm-5 py-3 flex-wrap">
                <p class="mat-title bg-transparent" style="width: fit-content;">On-The-Go Study Bible</p>
                <p class="mat-subheading-2 mt-auto mb-1">Why use the Bible Software?</p>

                <span class="mb-2">This Bible application was designed to be convenient to engage even when the user is
                    not available to read with the eyes, an audio player was integrated to remediate this. Yet, it was
                    designed with saving user data in mind. It is intended to have 22+ Versions available at this time.
                    At a later time these versions might reduce or increase. At this time it is available as PWA but,
                    plans are being made to design a Native Application to iOS and Android Platforms. Finally, it is
                    free of Charge!</span>

                <a mat-raised-button class="d-flex px-3 px-sm-4 py-2 my-2 text-white rounded-pill"
                    href="//downloads.evryword.com.ng" color="primary" style="width: fit-content;">
                    <span class="my-auto text-wrap">Download Now</span>
                </a>

                <div class="d-flex flex-column w-100 py-2 py-sm-5 ewd-product">
                    <p class="mat-title bg-transparent" style="width: fit-content;">REST API</p>
                    <div class="d-flex w-100 flex-wrap">
                        <span class="mat-subheading-2">Easy to use, fast and no force majeure! Also open to
                            contributions.</span>
                    </div>

                    <a href="//docs.evryword.com.ng" mat-raised-button
                        class="d-flex px-3 px-sm-4 py-2 mt-2 mb-auto text-white rounded-pill" target="_blank"
                        color="primary" style="width: fit-content;">
                        <span class="text-wrap">Visit Docs</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <p class="mat-subheading-1">
        All Products & Services are free to use and at no cost for salvation is free for all men! These products and
        services do not rely on donations and or contributions to remain maintained. God is entirely capable of
        providing for Himself. This we declare with all sense of humility and respect. {{this.anchorScripture$ | async}}
        &#8212; <span class="text-capitalize">{{this.anchorScriptureRef.bk}}
            {{this.anchorScriptureRef.chp}}:{{this.anchorScriptureRef.vrs}}</span>
    </p>
</div>
