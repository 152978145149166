import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ScriptureService } from '../services/scripture.service';

export interface Revelation {
  url: string;
  heading: string;
  reference: {[key in string]: string | number};
  passage?: Observable<string[]>;
}

@Component({
  selector: 'ewd-more-revelation',
  templateUrl: './more-revelation.component.html',
  styleUrls: ['./more-revelation.component.scss']
})
export class MoreRevelationComponent implements OnInit {
  public revelations: Revelation[] = [
    {
      url: 'https://storage.googleapis.com/gci-resources/evryword/assets/all-men.svg',
      heading: 'God\'s Purpose',
      reference: {
        vrs: 8,
        chp: 10,
        bk: 'zechariah',
      },
    },
    {
      url: 'https://storage.googleapis.com/gci-resources/evryword/assets/people.png',
      heading: 'Salvation Everywhere',
      reference: {
        vrs: 4,
        chp: 2,
        bk: '1 timothy',
      },
    },
    {
      url: 'https://storage.googleapis.com/gci-resources/evryword/assets/study.png',
      heading: 'Study',
      reference: {
        vrs: 15,
        chp: 2,
        bk: '2 timothy',
      },
    },
    {
      url: 'https://storage.googleapis.com/gci-resources/evryword/assets/water-well.png',
      heading: 'Access',
      reference: {
        vrs: 3,
        chp: 12,
        bk: 'isaiah',
      },
    },
    {
      url: 'https://storage.googleapis.com/gci-resources/evryword/assets/washing-water.png',
      heading: 'Washing of the Water',
      reference: {
        vrs: 26,
        chp: 5,
        bk: 'ephesians',
      },
    },
    {
      url: 'https://storage.googleapis.com/gci-resources/evryword/assets/bread-basket.png',
      heading: 'Winning Bread',
      reference: {
        vrs: 4,
        chp: 4,
        bk: 'matthew',
      },
    },
  ];

  constructor(private scriptureService: ScriptureService) { }

  ngOnInit(): void {
    this.revelations.forEach(revelation => {
      this.initRevelations(revelation);
    });
  }

  private initRevelations(revelation: Revelation): void {
    revelation.passage = this.scriptureService.getScripture(revelation.reference as any) as any;
  }
}
