<mat-toolbar color="accent" class="shadow-lg" style="background-image: url(https://storage.googleapis.com/gci-static-assets/maze-stroke.png); background-position: 50%; background-size: contain; background-repeat: repeat-x;">
    <div class="d-flex container py-0">
        <a [routerLink]="['/']" class="my-auto">
            <img src="https://storage.googleapis.com/gci-resources/evryword/icons/icon-72x72.png" alt="product-logo" [ngStyle]="{'objectFit': 'contain', objectPosition: '50%', width: '36px', height: '36px'}">
        </a>
        <a mat-button class="d-none d-sm-flex ms-auto my-auto py-1 py-sm-2 px-3 rounded-pill" href="//study.evryword.com.ng" target="_black" color="primary">
            <span class="my-auto">Study</span>
        </a>
        <a mat-button class="d-flex ms-auto ms-sm-2 my-auto rounded-pill py-1 py-sm-2 px-3" href="https://downloads.evryword.com.ng" target="_black">
            <mat-icon class="my-auto">download</mat-icon>
            <span class="ms-2 my-auto">Download</span>
        </a>
    </div>
</mat-toolbar>
<router-outlet></router-outlet>
<ewd-footer></ewd-footer>
