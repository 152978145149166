import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ewd-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
})
export class OurTeamComponent implements OnInit {
  public management: { name: string; imageUrl: string; role: string }[] = [
    {
      name: 'Israel Oluwole',
      imageUrl:
        'https://storage.googleapis.com/gci-static-assets/imole/team/management/israel-oluwole.jpeg',
      role: 'CEO',
    },
    {
      name: 'Tobi Dawodu',
      imageUrl:
        'https://storage.googleapis.com/gci-static-assets/imole/team/management/tobi-dawodu.jpeg',
      role: 'Product Design',
    },
    {
      name: 'Kennedy Imoni',
      imageUrl:
        'https://storage.googleapis.com/gci-static-assets/imole/team/management/kennedy-imoni.jpeg',
      role: 'Support',
    },
    {
      name: 'Momore Oladeinde',
      imageUrl:
        'https://storage.googleapis.com/gci-static-assets/imole/team/management/momore-oladeinde.jpeg',
      role: 'Projects',
    },
  ];
  public advisory: { name: string; imageUrl: string; role: string }[] = [
    {
      name: 'Adetola Adetoro',
      imageUrl:
        'https://storage.googleapis.com/gci-static-assets/imole/team/advisory/adetola-adetoro.jpeg',
      role: 'Senior Consultant, Resilience and Risk Management (PwC, London)',
    },
    {
      name: 'Olumide Oluwole',
      imageUrl:
        'https://storage.googleapis.com/gci-static-assets/imole/team/advisory/olumide-oluwole.jpeg',
      role: 'Chartered Accountant (Gennex Tech., Nigeria)',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
