<div class="d-flex flex-column container py-5" id="contact-us">
    <div class="d-flex flex-column py-2 mx-auto ewd-contact-header">
        <p class="mat-headline mx-auto text-center">Get in Touch</p>
        <span class="mat-caption text-center">Want a closer discussion? Let us know and we would contact you.</span>
    </div>

    <div class="d-flex flex-wrap mx-auto my-5 ewd-contact-pills-section">
        <div *ngFor="let item of contactPills; index as i"
            class="d-flex flex-column ewd-contact-pills px-2 py-2 m-1 rounded-3"
            [ngClass]="{'ewd-contact-pills--active': selectedPill === i}" (click)="selectedPill = i">
            <mat-icon class="mx-auto mt-auto">{{item.icon}}</mat-icon>
            <span class="mat-caption mx-auto mb-auto text-capitalize">{{item.title}}</span>
        </div>
    </div>

    <div class="d-flex flex-column mx-auto mb-5">
        <ng-container *ngIf="pillInfo[selectedPill].type === 'tel'">
            <span class="mat-h6">All contacts are available on WhatsApp.</span>
            <a *ngFor="let item of pillInfo[selectedPill].data" class="text-center" href="tel:{{item}}">{{item}}</a>
        </ng-container>
        <ng-container *ngIf="pillInfo[selectedPill].type === 'address'">
            <a *ngFor="let item of pillInfo[selectedPill].data" class="text-center" type="address">{{item}}</a>
        </ng-container>
    </div>

    <div class="d-flex flex-column">
        <div class="d-flex flex-column col-9 mx-auto">
            <span class="mb-2 mat-h6">Contact Form</span>
            <span *ngIf="contactForm.errors?.nosender && (contactForm.touched || contactForm.dirty)"
                class="mat-caption text-danger">One of email, phone number and name at the least is required.</span>

            <form class="d-flex flex-column w-100 mt-2" [formGroup]="contactForm">
                <div class="d-flex flex-wrap w-100">
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                        <mat-label>Your Name</mat-label>
                        <input matInput formControlName="name" class="ml-2" type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" class="ml-2" type="email">
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                        <mat-label>Phone Number</mat-label>
                        <input matInput formControlName="phone_number" class="ml-2" type="tel">
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                        <mat-label>Subject</mat-label>
                        <input matInput formControlName="subject" class="ml-2" type="text">
                        <mat-error *ngIf="controls.subject.invalid" class="mt-3">Subject is required</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" class="w-100" rows="14"></textarea>
                        <mat-error *ngIf="controls.description.invalid" class="mt-3">Message description is
                            required</mat-error>
                    </mat-form-field>
                </div>

                <a mat-raised-button class="d-flex mx-auto mt-4 rounded-pill py-1 py-sm-2 px-4 text-white"
                    color="primary" [disabled]="contactForm.invalid" (click)="submitForm()">
                    <mat-icon class="my-auto">send</mat-icon>
                    <span class="ms-2 my-auto">Submit</span>
                </a>
            </form>
        </div>
    </div>
</div>
