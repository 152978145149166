import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'ewd-reach-out',
  templateUrl: './reach-out.component.html',
  styleUrls: ['./reach-out.component.scss'],
})
export class ReachOutComponent implements OnInit {
  public selectedPill = 0;
  public contactPills = [
    {
      title: 'Phone number',
      icon: 'smartphone',
    },
    {
      title: 'Address',
      icon: 'place',
    },
  ];
  public pillInfo: { [key in string]: any } = {
    0: {
      type: 'tel',
      data: ['+2348166473017'],
    },
    1: {
      type: 'address',
      data: ['8, Dada Avenue, Aboru. Iyana-Ipaja, Lagos'],
    },
  };
  public contactForm!: FormGroup;
  public email = 'hello@evryword.com.ng'

  constructor(public fb: FormBuilder) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: new FormControl(null),
      phone_number: new FormControl(null),
      email: new FormControl(null),
      subject: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
    });

    this.contactForm.setValidators(this.senderValidator);
  }

  public submitForm() {
    const data = this.contactForm.getRawValue();
    const subject = `Hello YAF - Global`;
    const body = `Name: ${data.name}%0APhone: ${data.phone}%0AEmail: ${data.email}%0A%0A****** Your Message ****** %0A${data.message}`;

    window.location.assign(
      new URL(
        `mailto:${this.email}?to=${this.email}&subject=${subject}&body=${body}`
      ).toString()
    );
  }

  public senderValidator(f: AbstractControl): ValidationErrors | null {
    return [f.get('name'), f.get('phone_number'), f.get('email')].some((t) => {
      return t?.value !== null && t?.value !== undefined && t?.value !== '';
    })
      ? null
      : { nosender: true };
  }

  public get controls(): { [key in any]: AbstractControl } {
    return this.contactForm.controls;
  }
}
