import {
  animate,
  animation,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ScripturePayload,
  ScriptureService,
} from '../services/scripture.service';

export interface Quickcuts {
  icon?: string;
  name: string;
  link: string;
  target?: string;
  external?: boolean;
}

export const transAnimation = animation([
  style({
    opacity: '{{ opacity }}',
    transition: 'all easein',
  }),
  animate('{{ time }}'),
]);

@Component({
  selector: 'ewd-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
  animations: [
    trigger('revealRevelation', [
      transition(':enter', [
        useAnimation(transAnimation, {
          params: {
            opacity: 0,
            time: '2s',
          },
        }),
      ]),
    ]),
  ],
})
export class HeroSectionComponent implements OnInit {
  public heroRevelation$!: Observable<string[]>;
  public heroQuickcuts: Quickcuts[] = [
    {
      name: 'Accept Jesus',
      icon: 'person_add',
      link: '#salvation',
      target: '_self',
    },
    {
      name: 'Study',
      icon: 'auto_stories',
      link: 'https://study.evryword.com.ng',
      target: '_blank',
      external: true,
    },
    {
      name: 'Who we are', // link to Rationales
      icon: 'corporate_fare',
      link: `#rationales`,
      target: '_self',
    },
    {
      name: 'Products',
      icon: 'apps',
      link: `#products`,
      target: '_self',
    },
    {
      name: 'Developers',
      icon: 'api',
      link: 'https://dev.evryword.com.ng',
      target: '_blank',
      external: true,
    },
    // {
    //   name: 'Store',
    //   icon: 'store',
    //   link: 'https://paystack.shop/evryword',
    //   target: '_blank',
    //   external: true,
    // },
    // {
    //   name: 'Donate',
    //   icon: 'payment',
    //   link: 'https://paystack.com/pay/evryword',
    //   target: '_blank',
    //   external: true,
    // },
    {
      name: 'Supports & Feedback',
      icon: 'support_agent',
      link: '#contact-us',
      target: '_self',
    },
    // {
    //   name: 'Downloads',
    //   icon: 'get_app',
    //   link: `/downloads`,
    //   target: '_blank',
    // },
  ];
  public anchorScripture: ScripturePayload = {
    bk: 'matthew',
    chp: '4',
    vrs: '4',
  };

  constructor(private scriptureService: ScriptureService) {}

  ngOnInit(): void {
    this.heroRevelation$ = this.getHeroVerse();
  }

  public openLink(link: string): void {
    return openLink.bind(this, link)();
  }

  public getHeroVerse(): Observable<string[]> {
    return this.scriptureService.getScripture({
      bk: 'matthew',
      chp: '4',
      vrs: '4',
    });
  }
}

export function openLink(this: any, link: string): void {
  if (!link.includes('#')) {
    const url = new URL(link);
    window.open(url.href, '_blank');
  } else {
    this.router.navigateByUrl(link);
  }
}
