<footer class="w-100">
    <div class="d-flex flex-column container pt-5">
        <div class="d-flex flex-wrap w-100 py-2">
            <ng-container *ngFor="let linkLists of links">
                <div class="d-flex flex-column col-6 col-sm-4 col-lg-3 py-2">
                    <a *ngFor="let item of linkLists" class="my-2 mat-subheading-1" style=" cursor: pointer;" (click)="openLink(item.link)">
                        <span>{{item.name}}</span>
                    </a>
                </div>
            </ng-container>
            <div class="d-flex flex-column col-6 col-sm-4 col-lg-3 py-2 ewd-social-icons-container">
                <p class="mat-subheading-1">SOCIAL SPACE</p>
                <div class="d-flex w-100 flex-wrap">
                    <a class="d-flex mx-2 my-auto" href="mailto:{{email}}" target="_blank">
                        <mat-icon class="my-auto">mail_outline</mat-icon>
                    </a>
                    <span class="mx-2 my-auto">|</span>
                    <a class="mx-2 my-auto" href="https://www.instagram.com/ev_ryword/" target="_blank">
                        <img src="https://storage.googleapis.com/gci-static-assets/instagram-white.png" alt="instagram">
                    </a>    
                    <a class="mx-2 my-auto" href="https://www.facebook.com/evryWordofGod" target="_blank">
                        <img src="https://storage.googleapis.com/gci-static-assets/facebook-white.png" alt="facebook">
                    </a>
                    <a class="mx-2 my-auto" href="" target="_blank">
                        <img src="https://storage.googleapis.com/gci-static-assets/twitter-white.png" alt="twitter">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex py-3 mt-auto ewd-footer-copyright">    
        <p class="mat-subheading-1 text-center mx-auto my-auto">Copyright 2021 Ev'ryWord</p>
    </div>
</footer>
