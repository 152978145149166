import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { LightboxModule } from 'ngx-lightbox';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { HeroSectionComponent } from './hero-section/hero-section.component';
import { MoreRevelationComponent } from './more-revelation/more-revelation.component';
import { ProductsComponent } from './products/products.component';
import { FooterComponent } from './footer/footer.component';
import { MerchandiseComponent } from './merchandise/merchandise.component';
import { LightboxComponent } from 'ngx-lightbox/lightbox.component';
import { SalvationComponent } from './salvation/salvation.component';
import { ReachOutComponent } from './reach-out/reach-out.component';
import { DonationsComponent } from './donations/donations.component';
import { ImoleLogoComponent } from './imole-logo/imole-logo.component';
import { OurTeamComponent } from './our-team/our-team.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HeroSectionComponent,
    MoreRevelationComponent,
    ProductsComponent,
    FooterComponent,
    MerchandiseComponent,
    SalvationComponent,
    ReachOutComponent,
    DonationsComponent,
    ImoleLogoComponent,
    OurTeamComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    MatDividerModule,
    LightboxModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  entryComponents: [LightboxComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
