import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { openLink, Quickcuts } from '../hero-section/hero-section.component';

@Component({
  selector: 'ewd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public email: string = 'hello@evryword.com.ng';
  public links: Quickcuts[][] = [
    [
      {
        link: '#salvation',
        name: 'Accept Jesus',
        target: '_self',
      },
      {
        link: `#rationales`,
        name: 'Who we are',
        target: '_self',
      },
      {
        link: 'https://study.evryword.com.ng',
        name: 'Study',
        target: '_blank',
      },
      {
        link: 'https://dev.evryword.com.ng',
        name: 'Developers',
        target: '_blank',
      },
    ],
    [
      // {
      //   link: 'https://paystack.com/pay/evryword',
      //   name: 'Donate',
      //   target: '_blank',
      // },
      {
        link: '#products',
        name: 'Products',
        target: '_blank',
      },
      {
        link: `${window.location.protocol}${window.location.hostname}/downloads`,
        name: 'Downloads',
        target: '_blank',
      },
      {
        link: 'https://paystack.shop/evryword',
        name: 'Store',
        target: '_blank',
      },
    ],
    [
      {
        link: '#team',
        name: 'Our Team',
        target: '_blank',
      },
      {
        link: `${window.location.protocol}${window.location.hostname}/careers`,
        name: 'Careers',
        target: '_blank',
      },
      {
        link: '#contact-us',
        name: 'Support & Feedback',
        target: '_self',
      },
    ],
    [
      {
        link: `${window.location.protocol}${window.location.hostname}/terms-conditions`,
        name: 'Terms & Conditions',
        target: '_blank',
      },
      {
        link: `${window.location.protocol}${window.location.hostname}/privacy`,
        name: 'Privacy',
        target: '_blank',
      },
    ],
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public openLink(link: string): void {
    return openLink.bind(this, link)();
  }
}
