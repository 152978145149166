<div class="d-flex flex-column container px-sm-3 py-5 mt-5 rounded-3">
    <div class="d-flex w-100 flex-wrap">
        <div class="d-flex flex-column col-12 col-md-4 py-3 ewd-product-shots-container position-relative">
            <div #imgContainer class="d-flex flex-column flex-wrap w-100" style="overflow-y: hidden; overflow-x: auto;">
                <img *ngFor="let item of images; index as i;" class="h-100 m-auto" src="{{item}}" alt="shots-{{i + 1}}" (click)="openImage(i)">
            </div>

            <button mat-mini-fab class="d-none d-sm-block" color="accent" style="position: absolute; border-radius: 50%; top: calc(50% - (40px/2) - 16px); left: calc(-75px/2);" (click)="imgContainer.scrollBy({left: -240, top: 0, behavior: 'smooth'})" >
                <mat-icon class="m-auto">arrow_backward</mat-icon>
            </button>
            <button mat-mini-fab class="d-none d-sm-block" color="accent" style="position: absolute; border-radius: 50%; top: calc(50% - (40px/2) - 16px); right: calc(-40px/2);" (click)="imgContainer.scrollBy({left: 240, top: 0, behavior: 'smooth'})" >
                <mat-icon class="m-auto">arrow_forward</mat-icon>
            </button>
        </div>

        <div class="d-flex flex-column col-12 col-md-8 px-sm-5 py-3 py-sm-5">
            <p class="mat-body-1 mt-auto">
                Proclaiming the gospel in and with our everyday lives. "{{merchandiseScriptureCaption$ | async}}" &#8212;
                <span class="my-auto ms-1 text-capitalize">{{merchandiseScriptureCaptionRef.bk}} {{merchandiseScriptureCaptionRef.chp}}:{{merchandiseScriptureCaptionRef.vrs}}</span>
            </p>

            <li class="py-2 pt-sm-3 rounded">
                <ul>&#8212; Fashionable everywhere</ul>
                <ul>&#8212; Convenient to Wear</ul>
                <ul>&#8212; Online Payment</ul>
                <ul>&#8212; Doorstep Delivery</ul>
            </li>

            <p class="mat-subheading-1 mb-1">
                Guess what? A merchandise for New Converts to remind them of their biggest step ever is coming soon and free for all of 'em, yay!
            </p>
            <span class="mat-subheading-1">If the world gets to know about Jesus from my apparel, let's do it!</span>
            <a href="//paystack.shop/evryword" mat-raised-button class="rounded-pill px-5 py-2 mb-auto text-white" target="_blank" color="accent" style="width: fit-content;">
                <span class="text-wrap">Buy now!</span>
            </a>
        </div>
    </div>
</div>
