import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ScripturePayload, ScriptureService } from '../services/scripture.service';

@Component({
  selector: 'ewd-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  public images: string[] = [
    'https://storage.googleapis.com/gci-resources/evryword/assets/evryword-shot-1.png',
    'https://storage.googleapis.com/gci-resources/evryword/assets/evryword-shot-2.png',
  ];
  public anchorScripture$!: Observable<string[]>;
  public anchorScriptureRef: ScripturePayload = {
    bk: '1 corinthians',
    chp: '14',
    vrs: '40',
  };
  public productImages: string[] = [
    'https://storage.googleapis.com/gci-static-assets/imole/products/sw.png',
    'https://storage.googleapis.com/gci-static-assets/imole/products/ev-smart-plug-one.png',
    'https://storage.googleapis.com/gci-static-assets/imole/products/ev-smart-wall-socket.png',
    'https://storage.googleapis.com/gci-static-assets/imole/products/ev-smart-wall-switch-one-gang.png',
    'https://storage.googleapis.com/gci-static-assets/imole/products/ev-smart-wall-switch-two-gang.png',
    'https://storage.googleapis.com/gci-static-assets/imole/products/ev-smart-wall-switch-three-gang.png',
    'https://storage.googleapis.com/gci-static-assets/imole/products/relay-switch.png',
  ];

  constructor(private scriptureService: ScriptureService) {}

  ngOnInit(): void {
    this.anchorScripture$ = this.scriptureService.getScripture(
      this.anchorScriptureRef
    );
  }
}
