<div class="d-flex flex-column container px-0 py-5" id="rationales">
    <p class="mat-headline text-uppercase mx-2 mx-sm-0">Our Rationales</p>
    <div class="d-flex flex-wrap w-100">
        <div *ngFor="let item of revelations" class="d-flex flex-column flex-sm-row col-12 col-lg-6 px-2 px-sm-0 py-3 my-3 rounded ewd-rationales">
            <img class="mx-auto mx-sm-0 mb-3 my-sm-auto px-0 px-sm-3" src="{{item.url}}" alt="image-caption" [ngStyle]="{objectFit: 'contain', width: '256px', height: '256px', objectSize: '50%'}" loading="lazy">
            <div class="d-flex flex-column ms-2 my-auto">
                <span class="mat-title mt-sm-auto">{{item.heading}}</span>
                <p class="d-flex flex-column mat-subheading-2 mb-sm-auto"
                    [ngClass]="{'mx-auto': item.passage}">
                    <span *ngIf="item.passage; else loadingImage;">{{item.passage | async}}</span> <span class="text-capitalize ms-auto mt-2">&#8212; {{item.reference.bk}} {{item.reference.chp}}:{{item.reference.vrs}}</span>
                </p>

                <ng-template #loadingImage>
                    <span>Loading Scripture...</span>
                </ng-template>
            </div>
        </div>
    </div>
</div>
