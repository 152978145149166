<div class="d-flex w-100 ewd-hero-section py-5"
    style="background-image: url(https://storage.googleapis.com/gci-static-assets/maze-stroke.png); background-position: 50%; background-size: contain; background-repeat: repeat;">
    <div class="d-flex flex-column w-100 py-5 mt-sm-5 container">
        <span class="mat-display-2 mx-auto mt-5 mb-3">Ev'ryWord</span>
        <p @revealRevelation *ngIf="heroRevelation$ !== undefined"
            class="d-flex flex-wrap mat-subheading-1 mb-5 text-center mx-auto ewd-main-scripture"
            style="justify-content: center;">"{{heroRevelation$ | async}}" <span class="text-capitalize ms-2">&#8212;
                {{anchorScripture.bk}} {{anchorScripture.chp}}:{{anchorScripture.vrs}}</span></p>

        <div @revealRevelation class="d-flex rounded-pill ps-3 mx-auto mb-2 mb-sm-5 bg-white ewd-search-container">
            <img class="m-auto" src="https://storage.googleapis.com/gci-resources/evryword/icons/icon-72x72.png"
                alt="product-logo" style="width: 24px; height: 24px;">
            <input type="text" class="w-100 my-auto rounded-pill ps-3 py-2" style="height: 44px !important;"
                placeholder="What would you want from God?">
            <button mat-flat-button class="d-flex my-auto ms-auto p-0"
                style="min-width: fit-content; width: fit-content; height: fit-content; border-radius: inherit;">
                <mat-icon class="m-2" color="primary">search</mat-icon>
            </button>
        </div>

        <div @revealRevelation class="d-flex flex-wrap w-100 py-5 mx-auto ewd-quick-cuts">
            <ng-container *ngFor="let item of heroQuickcuts; index as i">
                <a *ngIf="item.external" mat-raised-button
                    class="d-flex rounded-pill px-3 px-sm-4 py-2 py-sm-3 my-2 mx-2" color="accent"
                    style="width: fit-content; word-break: break-all; white-space: pre-line; line-height: normal; cursor: pointer;"
                    [ngClass]="{'d-md-flex': i > 3, 'd-none': i > 3}" href="{{item.link}}">
                    <mat-icon class="mx-auto my-auto">{{item.icon}}</mat-icon>
                    <span class="my-auto ms-2 text-wrap text-capitalize">{{item.name}}</span>
                </a>

                <a *ngIf="!item.external && item.link.includes('#')" mat-raised-button class="d-flex rounded-pill px-3 px-sm-4 py-2 py-sm-3 my-2 mx-2"
                    color="accent"
                    style="width: fit-content; word-break: break-all; white-space: pre-line; line-height: normal; cursor: pointer;"
                    [ngClass]="{'d-md-flex': i > 3, 'd-none': i > 3}" href="{{item.link}}">
                    <mat-icon class="mx-auto my-auto">{{item.icon}}</mat-icon>
                    <span class="my-auto ms-2 text-wrap text-capitalize">{{item.name}}</span>
                </a>

                <a *ngIf="!item.external && !item.link.includes('#')" mat-raised-button
                    class="d-flex rounded-pill px-3 px-sm-4 py-2 py-sm-3 my-2 mx-2" color="accent"
                    style="width: fit-content; word-break: break-all; white-space: pre-line; line-height: normal; cursor: pointer;"
                    [ngClass]="{'d-md-flex': i > 3, 'd-none': i > 3}" [routerLink]="[item.link]">
                    <mat-icon class="mx-auto my-auto">{{item.icon}}</mat-icon>
                    <span class="my-auto ms-2 text-wrap text-capitalize">{{item.name}}</span>
                </a>
            </ng-container>
        </div>
    </div>
</div>
