import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ScripturePayload {
  vrsn?: string;
  chp: string;
  vrs: string;
  bk: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScriptureService {
  private baseUrl = `https://api.evryword.com.ng/v1/api`;

  constructor(private httpClient: HttpClient) { }

  public getScripture(payload: ScripturePayload): Observable<string[]> {
    return this.httpClient.get<{request: string[]}>(`${this.baseUrl}${payload.vrs ? '/vrs' : ''}`, {
      headers: {'Content-type': 'application/json'},
      responseType: 'json',
      params: {
        vrsn: payload.vrsn ?? 'kjv',
        ...payload,
      },
      observe: 'body'
    })
    .pipe(
      map((data) => data.request)
    );
  }
}
