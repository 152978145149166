<div id="team" class="d-flex flex-column container mt-5 px-0">
    <p class="mx-auto my-0 mat-headline text-capitalize text-dark">Our Team</p>

    <span class="mx-auto mat-headline">Management</span>

    <div class="d-flex flex-wrap w-100 mt-3" style="justify-content: space-around;">
        <div *ngFor="let item of management" class="d-flex flex-column mx-2 mb-3 mb-md-0">
            <img class="mx-auto mb-3 border-1 rounded-circle" [src]="item.imageUrl" alt="Management" style="width: 164px; max-height: 164px; object-fit: cover; object-position: center center;">

            <span class="mx-auto mb-0 mat-headline-5">{{ item.name }}</span>
            <span class="mx-auto mat-caption fw-bold">{{ item.role }}</span>
        </div>
    </div>

    <span class="mx-auto mt-5 mat-headline">Advisory</span>

    <div class="d-flex flex-wrap w-100 mt-3" style="justify-content: space-around;">
        <div *ngFor="let item of advisory" class="d-flex flex-column mx-2 mb-3 mb-md-0">
            <img class="mx-auto mb-3 rounded-circle" [src]="item.imageUrl" alt="Management" style="width: 164px; height: 164px;">
        
            <span class="mx-auto mb-0 mat-headline-5">{{ item.name }}</span>
            <span class="mx-auto mat-caption fw-bold">{{ item.role }}</span>
        </div>
    </div>
</div>
