import { Component, OnInit } from '@angular/core';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { Observable } from 'rxjs';
import { ScripturePayload, ScriptureService } from '../services/scripture.service';

@Component({
  selector: 'ewd-merchandise',
  templateUrl: './merchandise.component.html',
  styleUrls: ['./merchandise.component.scss']
})
export class MerchandiseComponent implements OnInit {
  public images: string[] = [
    'https://storage.googleapis.com/gci-resources/evryword/merchandise/jly-1.png',
    'https://storage.googleapis.com/gci-resources/evryword/merchandise/jly-2.png'
  ];
  private albums: IAlbum[] = [];
  public merchandiseScriptureCaptionRef: ScripturePayload = {
    bk: '1 peter',
    chp: '3',
    vrs: '3',
  };
  public merchandiseScriptureCaption$!: Observable<string[]>;

  constructor(private lightbox: Lightbox, private scriptureService: ScriptureService) { }

  ngOnInit(): void {
    this.merchandiseScriptureCaption$ = this.scriptureService.getScripture(this.merchandiseScriptureCaptionRef);
    this.images.forEach((item, i) => {
      this.albums.push({src: item, thumb: item, caption: `Merchandise - ${i + 1}`}
      );
    });
  }

  public openImage(index: number): void {
    this.lightbox.open(
      this.albums,
      index,
      {
        wrapAround: true,
        showRotate: true,
        centerVertically: true,
        fitImageInViewPort: true,
        alwaysShowNavOnTouchDevices: true,
        /* showZoom: true, */
        disableScrolling: true,
      });
  }
}
