<div class="d-flex flex-column container px-sm-3 py-3 py-sm-5" id="salvation">
    <div class="d-flex w-100 flex-wrap flex-md-wrap">
        <div class="col-12 col-md-5 py-3">
            <p class="mat-body">Get Saved!</p>
            <div class="d-flex flex-wrap w-100 mt-auto">
                <p class="mat-headline text-capitalize me-2 my-auto" style="line-height: 24px; text-anchor: middle;">Jesus</p>
                <ng-container *ngFor="let item of benefits; index as i">
                    <div *ngIf="i === focus" class="d-flex flex-wrap my-auto mat-headline ewd-salvation-benefit">
                        <ng-container *ngFor="let token of item.split(' ')">
                            <span @revealBenefit *ngIf="i === focus" class="text-capitalize pe-1" style="line-height: normal; max-width: fit-content; min-width: fit-content; width: fit-content; text-anchor: middle; font-size: inherit;">{{token}}</span>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <p class="mt-3">What are you, troubled, having no peace, joy, happiness, confused, needing help, in a dark place, struggling for acceptance, lonely, needing genuine love? Salvation is easy;</p>
            <p class="mat-body">{{pretextScripture$ | async}} &#8212; <span class="text-capitalize">{{pretextScripture.bk}} {{pretextScripture.chp}}:{{pretextScripture.vrs}}</span>
            </p>
            <p class="mat-body">And Christ's peace, that lasts forever will fill your heart and mind. Make the decision to be saved. Say the prayer in this video and Schedule a call and or Inform us now!</p>
        </div>

        <div class="d-flex flex-column col-12 col-md-7 px-md-3 px-xl-5 py-3">
            <iframe class="w-100" height="360" src="https://www.youtube.com/embed/XdlhJiEDqJ8/?controls=0&color=#ff8204&modestbranding=0&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <div class="d-flex flex-wrap mt-4">
                <button mat-raised-button class="rounded-pill px-4 py-2 me-3 my-1 my-sm-0 text-white" color="primary" style="width: fit-content; min-height: 56px;">
                    <mat-icon class="my-auto">phone_outline</mat-icon>
                    <span class="ms-2 my-auto text-wrap">Schedule a Call</span>
                </button>
                
                <button mat-raised-button class="rounded-pill px-4 py-2 my-1 my-lg-0 text-white" color="primary" style="width: fit-content; min-height: 56px;">
                    <mat-icon class="my-auto">emoji_emotions</mat-icon>
                    <span class="ms-2 my-auto text-wrap">Share your joy</span>
                </button>
            </div>
        </div>
    </div>

    <p class="mat-body mt-5">What does it mean now that I am Saved?!</p>
    <ol class="m-0">
        <li *ngFor="let item of afterSalvation" class="mat-body">{{item}}</li>
    </ol>
    <div class="d-flex flex-wrap w-100 my-4">
        <button mat-stroked-button class="rounded-pill px-4 py-2 me-3 my-1 my-sm-0" target="_blank" color="primary" style="width: fit-content;">
            <mat-icon class="my-auto">water</mat-icon>
            <span class="ms-2 my-auto text-wrap">Get Baptized</span>
        </button>
        <button mat-stroked-button class="rounded-pill px-4 py-2 my-1 my-sm-0" target="_blank" color="primary" style="width: fit-content;">
                <mat-icon class="my-auto">school</mat-icon>
                <span class="ms-2 my-auto text-wrap">Take Salvation Classes</span>
        </button>
    </div>

    <div class="d-flex flex-column mt-4">
        <p class="mat-body">
            {{preFinalActions$ | async}} <span class="text-capitalize">&#8212; {{preFinalActions.bk}} {{preFinalActions.chp}}:{{preFinalActions.vrs}}</span>
        </p>
        <span class="mat-body my-2 fw-bold">Find Nearest</span>

        <div class="d-flex flex-wrap my-4">
            <button mat-stroked-button class="rounded-pill px-4 py-2 me-3 my-1 my-sm-0" target="_blank" color="primary" style="width: fit-content;">
                <mat-icon class="my-auto">church</mat-icon>
                <span class="ms-1 my-auto text-wrap">Worship Center</span>
            </button>
            <button mat-stroked-button class="rounded-pill px-4 py-2 my-1 my-sm-0" target="_blank" color="primary" style="width: fit-content;">
                <mat-icon class="my-auto">house</mat-icon>
                <span class="ms-1 my-auto text-wrap">Homecell (Fellowship)</span>
            </button>
        </div>
    </div>

    <p class="mat-caption mx-auto mt-5 mb-1 text-center" style="letter-spacing: 8px;">Stay SAVED, study, get to know Jesus more...!</p>
</div>
